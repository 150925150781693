import React, { useEffect, useState } from "react"

const CircleSubMenu = ({
  data,
  setProducts,
  allProducts,
  filterMean,
  currentLanguage,
}) => {
  const [index, setIndex] = useState(0)

  let typeFilter = (search, filterMean) =>
    allProducts?.filter(wallpaper => {
      const angilal = wallpaper[filterMean]
      const result = angilal
        ? angilal.map(type => {
            return filterMean === "color" ? type.hex : type.name
          })
        : undefined

      return result ? result.includes(search) : false
    })

  const showProducts = (name, typeIndex) => {
    setIndex(typeIndex)
    const tempProducts = typeFilter(name, filterMean)
    setProducts(tempProducts)
  }

  useEffect(() => {
    const name = filterMean === "color" ? data[0].hex : data[0].name
    showProducts(name, 0)
  }, [filterMean])

  // useEffect(() => {
  //   const name = filterMean === "color" ? data[0].hex : data[0].name
  //   showProducts(name, index)
  // }, [currentLanguage])

  const menu = data
    ? data.map((type, typeIndex) => {
        const typeName = type?.name?.trim() ?? undefined
        const haveProduct = type?.wallpapers ?? false
        const tailbar = type?.tailbar?.trim() ?? undefined
        const image = type?.image ?? undefined
        const icon = type?.icon ?? undefined
        const hex = type?.hex?.trim() ?? undefined
        const name = filterMean === "color" ? hex : typeName
        const colorName = type?.colorName?.trim() ?? undefined
        const baraan = type?.baraan ?? undefined
        const wallpapersCount = type?.wallpapers
          ? type?.wallpapers.length
          : undefined

        return (
          haveProduct && (
            <button
              type="button"
              key={typeIndex}
              className={`grid outline-none focus:outline-none gap-1 group justify-items-center  
                ${
                  index === typeIndex ? "font-bold underline text-gray-900" : ""
                }`}
              onClick={() => {
                showProducts(name, typeIndex)
                // console.log(typeIndex)
              }}
            >
              {tailbar && <div className="hidden">{tailbar}</div>}

              {hex && (
                <div className="relative flex flex-col items-center justify-center w-full sm:py-4 group">
                  <div
                    className={`rounded-full flex opacity-90 items-center justify-center shadow h-12 w-12 sm:h-16 sm:w-16 ${
                      index === typeIndex
                        ? "ring-4 ring-offset-1 ring-brand opacity-100 z-20 h-16 w-16 shadow-lg absolute sm:h-24 sm:w-24"
                        : "relative"
                    } `}
                    style={{
                      backgroundColor: hex,
                      // WebkitBackfaceVisibility: "hidden",
                      // MozBackfaceVisibility: "hidden",
                      // WebkitTransform: "translate3d(0, 0, 0)",
                      // MozTransform: "translate3d(0, 0, 0)",
                    }}
                  >
                    {colorName && (
                      <div
                        className={`absolute inset-x-0 z-20 justify-center items-center py-1 leading-tight text-center rounded hidden  ${
                          index === typeIndex
                            ? "group-hover:flex text-xs font-bold sm:text-sm"
                            : ""
                        } ${baraan ? "text-white" : "text-gray-900"}`}
                      >
                        {colorName}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="relative flex flex-col items-center justify-center mb-6 group">
                {image && filterMean !== "pattern" && (
                  <div
                    className={`flex overflow-hidden rounded-full group-hover:border-cyan-600 ${
                      index === typeIndex
                        ? "ring-4 ring-offset-1 ring-cyan-600 z-10"
                        : ""
                    } ${
                      menuLength === 5
                        ? "sm:w-16 sm:h-16 h-12 w-12"
                        : "sm:w-24 sm:h-24 w-16 h-16"
                    }`}
                  >
                    {image}
                    <div className="absolute inset-0 w-full h-full">&nbsp;</div>
                  </div>
                )}
                {icon && filterMean === "pattern" && (
                  <div
                    className={`flex overflow-hidden rounded-full p-1 filter group-hover:border-brand sm:p-3 ${
                      index === typeIndex
                        ? "ring-4 ring-offset-1 ring-brand z-10 bg-brand-lighter opacity-100 bg-opacity-20"
                        : "opacity-80"
                    } ${
                      menuLength === 5
                        ? "sm:w-16 sm:h-16 h-8 w-8"
                        : "sm:w-24 sm:h-24 w-16 h-16"
                    }`}
                    style={{
                      WebkitBackfaceVisibility: "hidden",
                      MozBackfaceVisibility: "hidden",
                      WebkitTransform: "translate3d(0, 0, 0)",
                      MozTransform: "translate3d(0, 0, 0)",
                    }}
                  >
                    {icon}
                  </div>
                )}
                {typeName && (
                  <div className="absolute inset-x-0 px-1 pt-2 -mx-2 overflow-hidden text-sm leading-tight overflow-ellipsis line-clamp-2 group-hover:line-clamp-none sm:top-24 top-16 sm:mx-0">
                    {typeName}
                  </div>
                )}
              </div>
              {wallpapersCount && (
                <div className="hidden">{wallpapersCount}</div>
              )}
            </button>
          )
        )
      })
    : ""
  // console.log(menuLength)
  const menuLength = menu.filter(Boolean).length

  return (
    filterMean !== "luxury" && (
      <div className="px-2 bg-white">
        {/* <div className="flex flex-wrap flex-1 w-full py-10 mx-auto bg-white py -10 justify-evenly"> */}
        <div
          className={`grid w-full mx-auto justify-items-center px-2 sm:px-10 border-b lg:border-none border-gray-100 z-10 items-center ${
            filterMean === "color"
              ? "grid-cols-5 gap-1 sm:grid-cols-7 md:grid-cols-9 pb-3 pt-4 lg:pt-6"
              : menuLength === 1
              ? "grid-cols-1 pb-7 pt-2 lg:pt-6 max-w-sm"
              : menuLength === 2
              ? "grid-cols-2 pb-7 pt-2 lg:pt-6 max-w-lg"
              : menuLength === 3
              ? "grid-cols-3 pb-7 pt-2 lg:pt-6 gap-x-10 max-w-lg"
              : menuLength === 4
              ? "grid-cols-4 pb-7 pt-2 lg:pt-6"
              : menuLength === 5
              ? // ? "grid-cols-4 pb-7 pt-2 lg:pt-6"
                // : menuLength === 6
                "grid-cols-4 gap-x-4 gap-y-7 sm:grid-cols-5  pb-7 pt-2 lg:pt-6"
              : // : filterMean === "pattern"
                // ? "grid-cols-6 bg-red-400 gap-x-2"
                "grid-cols-4 gap-x-4 gap-y-7 sm:grid-cols-5 md:grid-cols-6 pb-7 pt-2 lg:pt-6 lg:grid-cols-7 p-2"
          } `}
        >
          {menu}
        </div>
      </div>
    )
  )
}

export default CircleSubMenu
