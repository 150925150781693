import React, { useEffect, useState } from "react"
import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"
import WallpaperBodySectionRightSide from "./WallpaperBodySectionRightSide"
import WallpaperSubMenu from "./WallpaperSubMenu"
import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/solid"
import { Link } from "gatsby"
import { RoomFilter } from "../graphql/SmallQueries"
import {
  AllTypeWall,
  AllOntslogWall,
  AllRoomWall,
  AllPatternWall,
  AllLuxuryWall,
  AllColorWall,
} from "../graphql/AllTypeWallpaperQuery"
import CardModal from "./CardModal"
import CircleSubMenu from "./CircleSubMenu"
import { useTranslation } from "react-i18next"
import {
  AngilalText,
  OntslogText,
  ColorText,
  RoomText,
  LuxuryText,
  PatternText,
  AllText,
} from "../graphql/QueryById"

const WallpaperMainBodySection = () => {
  const [value, setValue] = useState(0)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  let query = AllWallpapers()

  const sidebar = [
    {
      name: AllText(),
      dataSource: AllWallpapers(),
    },
    {
      name: OntslogText(),
      dataSource: AllOntslogWall(),
      filterMean: "ontslog",
    },
    {
      name: ColorText(),
      dataSource: AllColorWall(),
      filterMean: "color",
    },
    {
      name: RoomText(),
      dataSource: AllRoomWall(),
      filterMean: "room",
    },
    {
      name: PatternText(),
      dataSource: AllPatternWall(),
      filterMean: "pattern",
    },
    {
      name: LuxuryText(),
      dataSource: AllLuxuryWall(),
      filterMean: "luxury",
    },
  ]

  // let [data, setData] = useState(AllWallpapers()) // 1
  // let [data, setData] = useState(sidebar[0].dataSource)
  // console.log(data)

  // console.log(AllLuxuryWall())
  // useEffect(() => {
  //   setData(sidebar[value].dataSource)
  // }, [value, currentLanguage])

  // let [products, setProducts] = useState(AllWallpapers())
  // let [products, setProducts] = useState(data) /// 2

  const all = AllText()
  let [products, setProducts] = useState(query)
  const [allText, setAllText] = useState(all)
  // console.log(allText)

  useEffect(() => {
    setProducts(query)
    setAllText(all)
  }, [currentLanguage])

  // const setBackToAll = () => {
  //   setProducts(AllWallpapers())
  // }
  const setBackToAll = () => {
    setProducts(query)
    // setProducts(data) // 3
  }

  const sidebarItems = sidebar.map((item, index) => {
    return (
      <button
        key={index}
        className={`py-1 font-semibold text-gray-600 hover:text-gray-900 hover:bg-gray-300 focus:outline-none outline-none rounded-full px-2 ${
          index === value ? "bg-gray-300 text-gray-900" : ""
        }`}
        onClick={() => {
          if (item.name === allText) {
            setBackToAll()
          }
          setValue(index)
        }}
      >
        {item.name}
        {/* {console.log(item.name)} */}
      </button>
    )
  })

  let sortedProducts = products.sort(function (a, b) {
    var nameA = a.name?.toUpperCase()
    var nameB = b.name?.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    return 0
  })

  let cards = sortedProducts.map((item, index) => {
    return <CardModal key={index} data={item} />
  })

  // const [circleMenu, setCircleMenu] = useState(null)

  // useEffect(() => {
  //   setCircleMenu(
  //     sidebar[value].name !== "All" && (
  //       <CircleSubMenu
  //         data={sidebar[value].dataSource}
  //         // setProducts={setData}
  //         setProducts={setProducts}
  //         // allProducts={sidebar[0].dataSource}
  //         // allProducts={AllWallpapers()}
  //         allProducts={products}
  //         // allProducts={query} /// 4
  //         filterMean={sidebar[value].filterMean}
  //         currentLanguage={currentLanguage}
  //       />
  //     )
  //   )
  // }, [currentLanguage, value])

  return (
    <div className="relative mt-4 antialiased">
      <div className="relative grid max-w-screen-xl sm:mx-auto lg:mt-10 lg:space-x-4 lg:grid-cols-6 sm:px-0 md:mx-4 lg:mx-auto">
        {/* <div className="top-0 z-10 py-4 bg-white lg:shadow lg:sticky bg-opacity-90 sm:shadow-none"> */}
        <div className="top-0 z-10 py-4 bg-white lg:bg-transparent lg:sticky bg-opacity-90 sm:shadow-none">
          <div className="lg:sticky top-14">
            <div className="justify-center hidden pl-4 mt-4 text-sm font-bold text-gray-500 uppercase lg:flex">
              {AngilalText()}
            </div>
            <div
              className={`grid grid-cols-4 px-2 py-4 lg:border-none sm:grid-cols-6 lg:grid-cols-1 gap-y-2 antialiased ${
                sidebar[value].name !== allText &&
                sidebar[value].filterMean !== "luxury"
                  ? "border-b border-gray-100"
                  : ""
              }`}
            >
              {sidebarItems}
            </div>
          </div>
        </div>
        <div className="pb-10 sm:bg-white lg:col-span-5">
          <div className="">
            {/* {sidebar[value].name !== "All" && ( */}
            {sidebar[value].name !== allText && (
              <CircleSubMenu
                data={sidebar[value].dataSource}
                // setProducts={setData}
                setProducts={setProducts}
                // allProducts={sidebar[0].dataSource}
                // allProducts={AllWallpapers()}
                // allProducts={products}
                allProducts={query} /// 4
                filterMean={sidebar[value].filterMean}
                currentLanguage={currentLanguage}
              />
            )}
            {/* {circleMenu} */}

            <div className="grid grid-cols-2 gap-4 p-2 px-4 pt-10 md:grid-cols-4 lg:pb-4 lg:pt-10 justify-items-center md:px-4 sm:grid-cols-3">
              {cards}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WallpaperMainBodySection
