import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import QSee from "./QSee"
import { GatsbyContext } from "../context/context"
import Badge from "./Badge"

const CardModal = ({ data, small, noModal }) => {
  const { isModalOpen, showModal, hideModal } = useContext(GatsbyContext)
  const name = data?.name ?? ""
  const image = data?.image ?? ""

  // QuickView section
  const [modalOpen, setModalOpen] = useState(false)
  function handleClick() {
    setModalOpen(!modalOpen)
  }

  const badgeSource = <Badge data={data} />
  const [badge, setBadge] = useState()
  useEffect(() => {
    setBadge(badgeSource)
  }, [])

  return (
    <>
      <QSee
        data={data}
        modalOpen={modalOpen}
        modalClose={() => setModalOpen(false)}
        setModalOpen={setModalOpen}
      />
      <div
        className="relative flex flex-col antialiased rounded shadow cursor-pointer sm:max-w-xs group hover:shadow-lg"
        onClick={() => {
          handleClick()
          showModal()
        }}
      >
        <div
          className={`relative flex object-cover overflow-hidden rounded group-hover:rounded-b-none ${
            small ? "h-20" : "h-40 md:h-56"
          }`}
          style={{
            WebkitBackfaceVisibility: "hidden",
            MozBackfaceVisibility: "hidden",
            WebkitTransform: "translate3d(0, 0, 0)",
            MozTransform: "translate3d(0, 0, 0)",
          }}
        >
          {image}
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
          <div className="absolute inset-0 hidden transition bg-brand-dark opacity-10 group-hover:flex"></div>
          {badge}
        </div>
        <span
          className={`py-2  text-center text-gray-700 rounded-b group-hover:text-gray-900 text-sm ${
            small ? "font-normal" : "font-bold uppercase"
          }`}
        >
          {name}
        </span>
      </div>
    </>
  )
}

export default CardModal
