import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { CurrentLanguage } from "../components/MenuItems"
import { useTranslation } from "react-i18next" // 20210423

// const mn = () => {
//   return CurrentLanguage() === "mn"
// }

export const AllLuxuryWall = () => {
  // const { i18n } = useTranslation() // 20210423
  // const currentLanguage = i18n.language
  const currentLanguage = CurrentLanguage()

  const { AllLuxuryWall } = useStaticQuery(query)
  const mainPath = AllLuxuryWall?.nodes ?? undefined

  const data = mainPath
    ? mainPath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.mn ?? undefined
        const nameEn = path?.en ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = path?.Mongol_tailbar ?? undefined
        const textEn = path?.English_tailbar ?? undefined
        const tailbar = currentLanguage === "mn" ? textMn : textEn

        const imagePath = path?.Image ?? undefined
        const sharpImage = imagePath
          ? getImage(imagePath.localFiles[0])
          : undefined
        const image = sharpImage ? (
          <GatsbyImage image={sharpImage} alt={name} className="w-full" />
        ) : undefined

        const iconPath = path?.Icon ?? undefined
        const sharpIcon = iconPath
          ? getImage(iconPath.localFiles[0])
          : undefined
        const icon = sharpIcon ? (
          <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
        ) : undefined

        const wallpath = path?.wallpapers ?? undefined
        const wallpapers = wallpath
          ? wallpath.map(wallpaper => wallpaper.data.Name)
          : undefined

        return { name, tailbar, image, icon, wallpapers }
      })
    : undefined

  return data.filter(item => item.wallpapers)
}

export const AllRoomWall = () => {
  const currentLanguage = CurrentLanguage()
  const { AllRoomWall } = useStaticQuery(query)
  const mainPath = AllRoomWall?.nodes ?? undefined

  const room = mainPath
    ? mainPath
        .map((item, index) => {
          const path = item?.data ?? undefined
          const nameMn = path?.mn ?? undefined
          const nameEn = path?.en ?? undefined
          const name = currentLanguage === "mn" ? nameMn : nameEn

          const textMn = path?.Mongol_tailbar ?? undefined
          const textEn = path?.English_tailbar ?? undefined
          const tailbar = currentLanguage === "mn" ? textMn : textEn

          const imagePath = path?.Image ?? undefined
          const sharpImage = imagePath
            ? getImage(imagePath.localFiles[0])
            : undefined
          const image = sharpImage ? (
            <GatsbyImage image={sharpImage} alt={name} className="w-full" />
          ) : undefined

          const iconPath = path?.Icon ?? undefined
          const sharpIcon = iconPath
            ? getImage(iconPath.localFiles[0])
            : undefined
          const icon = sharpIcon ? (
            <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
          ) : undefined

          const wallpath = path?.wallpapers ?? undefined
          // console.log(wallpath)

          const wallpapers = wallpath
            ? wallpath
                .filter(item => !item.data.menuHide)
                .map(wallpaper => wallpaper.data.Name)
            : undefined

          return { name, tailbar, image, icon, wallpapers }
        })
        .filter(item => item.wallpapers)
    : undefined

  return room.filter(item => item.wallpapers.length !== 0)
}

export const AllTypeWall = () => {
  const currentLanguage = CurrentLanguage()
  const { AllTypeWall } = useStaticQuery(query)
  const mainPath = AllTypeWall?.nodes ?? undefined

  const type = mainPath
    ? mainPath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.mn ?? undefined
        const nameEn = path?.en ?? undefined
        const name = currentLanguage === "mn" ? nameMn : nameEn

        const textMn = path?.Mongol_tailbar ?? undefined
        const textEn = path?.English_tailbar ?? undefined
        const tailbar = currentLanguage === "mn" ? textMn : textEn

        const imagePath = path?.Image ?? undefined
        const sharpImage = imagePath
          ? getImage(imagePath.localFiles[0])
          : undefined
        const image = sharpImage ? (
          <GatsbyImage image={sharpImage} alt={name} className="w-full" />
        ) : undefined

        const iconPath = path?.Icon ?? undefined
        const sharpIcon = iconPath
          ? getImage(iconPath.localFiles[0])
          : undefined
        const icon = sharpIcon ? (
          <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
        ) : undefined

        const wallpath = path?.wallpapers ?? undefined
        const wallpapers = wallpath
          ? wallpath.map(wallpaper => wallpaper.data.Name)
          : undefined

        return { name, tailbar, image, icon, wallpapers }
      })
    : undefined

  return type.filter(item => item.wallpapers)
}

export const AllPatternWall = () => {
  const currentLanguage = CurrentLanguage()
  const { AllPatternWall } = useStaticQuery(query)
  const mainPath = AllPatternWall?.nodes ?? undefined

  const data = mainPath
    ? mainPath
        .map((item, index) => {
          const path = item?.data ?? undefined
          const nameMn = path?.mn ?? undefined
          const nameEn = path?.en ?? undefined
          const name = currentLanguage === "mn" ? nameMn : nameEn

          const textMn = path?.Mongol_tailbar ?? undefined
          const textEn = path?.English_tailbar ?? undefined
          const tailbar = currentLanguage === "mn" ? textMn : textEn

          const imagePath = path?.Image ?? undefined
          const sharpImage = imagePath
            ? getImage(imagePath.localFiles[0])
            : undefined
          const image = sharpImage ? (
            <GatsbyImage image={sharpImage} alt={name} className="w-full" />
          ) : undefined

          const iconPath = path?.Icon ?? undefined
          const sharpIcon = iconPath
            ? getImage(iconPath.localFiles[0])
            : undefined
          const icon = sharpIcon ? (
            <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
          ) : undefined

          const wallpath = path?.wallpapers ?? undefined
          const wallpapers = wallpath
            ? wallpath
                .filter(item => !item.data.menuHide)
                .map(wallpaper => wallpaper.data.Name)
            : undefined

          return { name, tailbar, image, icon, wallpapers }
        })
        .filter(item => item.wallpapers)
    : undefined

  return data.filter(item => item.wallpapers.length > 0)
}

export const AllOntslogWall = () => {
  const currentLanguage = CurrentLanguage()
  const { AllOntslogWall } = useStaticQuery(query)
  const mainPath = AllOntslogWall?.nodes ?? undefined

  const ontslog = mainPath
    ? mainPath
        .map((item, index) => {
          const path = item?.data ?? undefined
          const nameMn = path?.mn ?? undefined
          const nameEn = path?.en ?? undefined
          const name = currentLanguage === "mn" ? nameMn : nameEn

          const textMn = path?.Mongol_tailbar ?? undefined
          const textEn = path?.English_tailbar ?? undefined
          const tailbar = currentLanguage === "mn" ? textMn : textEn

          const imagePath = path?.Image ?? undefined
          const sharpImage = imagePath
            ? getImage(imagePath.localFiles[0])
            : undefined
          const image = sharpImage ? (
            <GatsbyImage image={sharpImage} alt={name} className="w-full" />
          ) : undefined

          const iconPath = path?.Icon ?? undefined
          const sharpIcon = iconPath
            ? getImage(iconPath.localFiles[0])
            : undefined
          const icon = sharpIcon ? (
            <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
          ) : undefined

          const wallpath = path?.wallpapers ?? undefined
          const wallpapers = wallpath
            ? wallpath
                .filter(item => !item.data.menuHide)
                .map(wallpaper => wallpaper.data.Name)
            : undefined

          return { name, tailbar, image, icon, wallpapers }
        })
        .filter(item => item.wallpapers)
    : undefined
  // console.log(ontslog)
  // console.log(ontslog[0].wallpapers)

  return ontslog.filter(item => item.wallpapers.length > 0)
}

export const AllColorWall = () => {
  const currentLanguage = CurrentLanguage()
  const { AllColorWall } = useStaticQuery(query)

  // console.log(AllColorWall)

  const mainPath = AllColorWall?.nodes ?? undefined

  const color = mainPath
    ? mainPath
        .map((item, index) => {
          const path = item?.data ?? undefined
          const colorHex = path?.hex ?? undefined
          const hex = colorHex.charAt(0) === "#" ? colorHex : "#" + colorHex

          const nameMn = path?.nameMn ?? undefined
          const nameEn = path?.nameEn ?? undefined
          const colorName = currentLanguage === "mn" ? nameMn : nameEn

          const genColorBefore = path?.genColor ?? undefined
          const baraan = genColorBefore === "Бараандуу" ? true : false

          const wallpath = path?.Wallpaper ?? undefined
          const wallpapers = wallpath
            ? wallpath
                .filter(item => !item.data.menuHide)
                .map(wallpaper => wallpaper.data.Name)
            : undefined

          return { hex, colorName, wallpapers, baraan }
        })
        .filter(item => item.wallpapers)
    : undefined

  // console.log(color)
  return color.filter(item => item.wallpapers.length !== 0)
}

const query = graphql`
  {
    AllLuxuryWall: allAirtable(filter: { table: { eq: "Luxury" } }) {
      nodes {
        data {
          mn
          en
          Mongol_tailbar
          English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 150, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          wallpapers: Wallpaper {
            data {
              Name
            }
          }
        }
      }
    }
    AllPatternWall: allAirtable(filter: { table: { eq: "Pattern" } }) {
      nodes {
        data {
          mn
          en
          Mongol_tailbar
          English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 150, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          wallpapers: Wallpaper {
            data {
              Name
              menuHide: Menu_Hide
            }
          }
        }
      }
    }
    AllRoomWall: allAirtable(filter: { table: { eq: "Room" } }) {
      nodes {
        data {
          mn
          en
          Mongol_tailbar
          English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 150, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          wallpapers: Wallpaper {
            data {
              Name
              menuHide: Menu_Hide
            }
          }
        }
      }
    }
    AllTypeWall: allAirtable(filter: { table: { eq: "Wallpaper Angilal" } }) {
      nodes {
        data {
          mn
          en
          Mongol_tailbar
          English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 150, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          wallpapers: Wallpaper {
            data {
              Name
            }
          }
        }
      }
    }
    AllOntslogWall: allAirtable(
      filter: { table: { eq: "Wallpaper Ontslog" } }
    ) {
      nodes {
        data {
          mn
          en
          Mongol_tailbar
          English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 150, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          wallpapers: Wallpaper {
            data {
              Name
              menuHide: Menu_Hide
            }
          }
        }
      }
    }
    AllColorWall: allAirtable(filter: { table: { eq: "Wallpaper Color" } }) {
      nodes {
        data {
          hex: Hex_Color
          nameMn: mn
          nameEn: en
          Wallpaper {
            data {
              Name
              menuHide: Menu_Hide
            }
          }
          genColor: General_color
        }
      }
    }
  }
`
