import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import WallpaperMainBodySection from "../components/WallpaperMainBodySection"
import PageHeader from "../templates/PageHeader"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import GeneralDescription from "../components/GeneralDescription"
import PageTitle from "../components/PageTitle"

const WallpaperPage = () => {
  const section = WallpaperDescription()

  return (
    <Layout>
      <SEO title={section.name} description='Тансаг, тухлаг тав тухыг мэдрүүлэгч, өндөр чанарын Япон ханын цаас, обой' />
      <div className="">
        {/* <PageHeader title={section.name} image={section.image} tall /> */}
        <PageTitle title={section.name} />
        <WallpaperMainBodySection />
        <GeneralDescription desc={section.md} sectionName={section.name} />
      </div>
    </Layout>
  )
}

export default WallpaperPage
